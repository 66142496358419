import Layout from '../components/Layout/Layout'

export default function About() {
  return (
    <>
      <Layout>
        <div>
          <section className="section-box mt-50">
            <div className="post-loop-grid">
              <div className="container">
                <div className="text-center">
                  <h6 className="f-18 color-text-mutted text-uppercase">
                    Our company
                  </h6>
                  <h2 className="section-title mb-10 wow animate__animated animate__fadeInUp">
                    About FindaJobUSA
                  </h2>
                  <p className="font-sm color-text-paragraph wow animate__animated animate__fadeInUp w-lg-50 mx-auto">
                    FindAJobUSA is where people connect with new job
                    opportunities. Whether you are an employer or job seeker,
                    FindAJobUSA makes job posting and searching easy. Search
                    from tens of thousands of jobs that focus on your specific
                    industry and line of work. Find the one that’s right for
                    you.
                  </p>
                </div>
                <div className="row mt-70">
                  <div className="col-lg-6 col-md-12 col-sm-12">
                    <img
                      src="assets/imgs/page/about/img-about2.jpg"
                      alt="joxBox"
                      className="px-lg-4"
                    />
                  </div>
                  <div className="col-lg-6 col-md-12 col-sm-12 text-start px-lg-4 mt-4 mt-sm-4 mt-md-4 mt-lg-0">
                    <strong className="mt-15">Our Mission</strong>
                    <p className="font-sm color-text-paragraph mt-20">
                      At FindaJobUSA, we believe that the right job can
                      transform lives and the right talent can transform
                      businesses. We bridge the gap between job seekers and
                      employers, creating a dynamic and efficient job market
                      where opportunities and talents find their perfect match.
                    </p>
                    <br />
                    <strong className="mt-15">What We Offer</strong>
                    <div className="mt-20">
                      <p className="font-sm color-text-paragraph mt-20">
                        <strong>1. Domestic Job Listings: </strong> We provide a
                        comprehensive database of job postings from all
                        industries and sectors across the USA. From entry-level
                        positions to executive roles, our platform caters to all
                        career stages and aspirations.
                      </p>
                      <p className="font-sm color-text-paragraph mt-20">
                        <strong>2. User-Friendly Interface</strong>Our website
                        is designed with you in mind. With easy navigation and
                        powerful search tools, finding your next job has never
                        been easier. Filter jobs by location, industry, or
                        search by keyword to find the perfect match quickly.
                      </p>
                      <p className="font-sm color-text-paragraph mt-20">
                        <strong>3. Employer Service:</strong>For employers, we
                        offer a range of services to help you find and attract
                        top talent. Post job openings, and attract domestic
                        talent.
                      </p>
                      <br />
                      <strong>Join Us Today</strong>
                      <p className="font-sm color-text-paragraph mt-20">
                        Whether you're a job seeker ready to take the next step
                        in your career or an employer looking to find the
                        perfect candidate, FindaJobUSA is here to help you
                        achieve your goals. Join our community today and take
                        advantage of the opportunities waiting for you.
                        <br />
                        <br />
                        Thank you for choosing FindaJobUSA. Your journey to
                        success starts here.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
