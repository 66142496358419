import Layout from '../components/Layout/Layout'
import { Link } from 'react-router-dom'
import { useState } from 'react'
import { sendPasswordResetEmail } from 'firebase/auth'
import { ToastContainer, toast } from 'react-toastify'
import { withFirebase } from '../firebase'
import 'react-toastify/dist/ReactToastify.css'

const Reset = (props) => {
  const auth = props.firebase.auth
  const [email, setEmail] = useState('')

  const onSubmit = (e) => {
    e.preventDefault()
    sendPasswordResetEmail(auth, email)
      .then(() => {
        toast.success('Password reset email sent!')
      })
      .catch((error) => {
        toast.warning('Fail to send Password reset email')
        console.error(error)
      })
  }

  const onChange = (e) => {
    setEmail(e.target.value)
  }

  return (
    <>
      <Layout>
        <section className="pt-100 login-register">
          <div className="container">
            <div className="row login-register-cover border-0">
              <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                <div className="text-center">
                  <p className="font-sm text-brand-2">Forgot Password</p>
                  <h2 className="mt-10 mb-5 text-brand-1">
                    Reset Your Password
                  </h2>
                  <p className="font-md text-paragraph-2 mb-30">
                    Enter email address associated with your account and we'll
                    send you a link to reset your password
                  </p>
                </div>
                <form
                  className="login-register text-start mt-20"
                  onSubmit={onSubmit}
                >
                  <div className="form-group">
                    <label
                      className="form-label font-sm text-muted"
                      htmlFor="input-1"
                    >
                      Email address *
                    </label>
                    <input
                      className="form-control"
                      id="input-1"
                      type="text"
                      required
                      name="emaill"
                      placeholder="stevenjob@gmail.com"
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-brand-2 hover-up w-100"
                      type="submit"
                      name="continue"
                    >
                      Continue
                    </button>
                  </div>
                  <div className="text-muted">
                    Don't Have an Account?
                    <Link className="text-brand-2" to="/register">
                      &nbsp;Sign&nbsp;up
                    </Link>
                  </div>
                  <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </form>
              </div>
              <div className="img-1 d-none d-lg-block">
                <img
                  className="shape-1"
                  src="assets/imgs/page/login-register/img-51.svg"
                  alt="JobBox"
                />
              </div>
            </div>
            <div className="row login-register-cover">
              <div className="img-2">
                <img
                  src="assets/imgs/page/login-register/eagle.svg"
                  alt="JobBox"
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default withFirebase(Reset)
