import Layout from '../../components/Layout/Layout'

const PrivacyPolicyPage = () => {
  return (
    <Layout>
      <section className="section-box terms-of-use"></section>
      <div className="container" style={{ textAlign: 'left' }}>
        <br />
        <br />
        <h4>Privacy Policy</h4>
        <br />
        <h6>
          <strong>Last Updated: August 20, 2024</strong>
        </h6>
        <br />
        <p>
          USA Job Solutions LLC (“<strong>USA Job Solutions</strong>”, “
          <strong>we</strong>”, “<strong>our</strong>”, or “<strong>us</strong>
          ”) is committed to respecting the privacy and security of your
          personal data. Please take the time to review this privacy policy (“
          <strong>Privacy Policy</strong>”) in order to understand how we
          collect, process, and share your personal data (“
          <strong>Personal Data</strong>”) through our offerings and digital
          properties that link to this Privacy Policy, including our website
          (collectively, the “Services”), as well as through interaction through
          social media, marketing activities, and other activities described in
          this Privacy Policy. This Privacy Policy does not apply to, or limit,
          use or disclosure of non personal information we may collect from you
          via your use of our Services.
        </p>
        <br />
        <p>
          This Privacy Policy does not apply to the extent that we process
          personal data as a data processor (or a comparable role such as a
          “service provider” under CCPA) for business customers or otherwise. To
          the extent such processing of Personal Data on behalf of business
          customers is applicable it will be governed by a data processing
          agreement mutually agreed to between the USA Job Solutions and such
          customer. \ We are not responsible for the privacy or data security
          practices of our customers, partners, or other third parties, which
          may differ from those set forth in this Privacy Policy.
        </p>
        <br />
        <h5>
          <strong>COLLECTING INFORMATION</strong>
        </h5>
        <br />
        <p>
          <strong>Information you share with us</strong>. You may provide us
          certain information that we request from you or that you provide to us
          such as: Identity Data: name, resume, username; Contact Data: mailing
          address, email address, telephone number; Financial Data: when
          necessary, such payment card details, however, please note that
          payment methods (e.g. credit card number or bank account details) are
          not collected by us directly, but by secure payment service providers;
          Transaction Data: details about payments to and from you; Profile
          Data: username and password, preferences, and user-generated content;
          Business Information: company name and location, job title.
        </p>
        <br />
        <p>You may provide us this information when you:</p>
        <br />
        <ul>
          <li>Register or set up an account</li>
          <li>Modify your account or profile</li>
          <li>Order and purchase Services</li>
          <li>
            Request to receive support, information, communications, or content
          </li>
          <li>Respond to surveys</li>
          <li>Participate in a promotion or other website feature</li>
          <li>Send messages through the Services</li>
          <li>Upload a job advertisement</li>
          <li>Apply to a job advertisement</li>
          <li>Contact us</li>
          <li>Provide us feedback</li>
        </ul>
        <br />
        <p>
          <strong>Automatic data collection</strong>. As detailed here and in
          the Navigation section below, we may also collect information like
          your internet protocol (“<strong>IP</strong>”) address, browser type,
          and access times, search term queries through a log of all traffic on
          our website. To improve our internal operations and the Services and
          to better understand your preferences and serve you, we may combine
          information you give us online with other information from our
          sources, transactions, and communications. This may include data from
          online marketing. We may also combine that information with data that
          is publicly available and data that we receive from other reputable
          sources.
        </p>
        <br />
        <h6>
          <strong>Information that should not be shared with us.</strong>
        </h6>
        <br />
        <p>
          Please do not share with us Sensitive Personal Data. We do not require
          any Sensitive Personal Data about you for you to utilize the Services.
          If you are submitting or sharing your resume with a prospective
          employer via the Services, please carefully consider the information
          that is contained within your resume and ensure that you are not
          providing any sensitive or other information that is not necessary for
          your job ap plication or job search. “
          <strong>Sensitive Personal Data</strong>” means Personal Data that is
          defined or considered as “sensitive” under applicable law, which may
          include data that reveals racial or ethnic origin, political opinions,
          religious or philosophical beliefs, trade union membership; genetic
          and biometric data, data concerning health, gender, sex or sexual
          orientation.
        </p>
        <br />
        <h5>
          <strong>USING INFORMATION</strong>
        </h5>
        <br />
        <p>
          We use the information we gather through the Services to provide
          services to you, provide support, operate, improve, and update the
          Services, send communications, and to prevent fraud and otherwise
          protect our Services. The information we gather through the Services
          may be shared with third parties to deliver our products and services.
        </p>
        <br />
        <p>
          Where applicable (e.g., to comply with the GDPR), our lawful basis for
          processing your Personal Data will depend on the nature of the
          Personal Data involved and the specific context in which it is
          collected. Your Personal Data may be processed with your consent, to
          perform a contract with you, for legitimate business interests where
          such interests are not overridden by your data privacy rights, with a
          legal obligation, or to protect your vital interests or those of
          another person. Where you’ve provided consent for our processing of
          Personal Data, you may withdraw your consent. If you agree to receive
          our marketing emails, for example, you may opt-out or withdraw your
          consent by unsubscribing at any time.
        </p>
        <br />
        <p>
          By creating an account with us, by managing your profile, or opting in
          when presented with choices, or using our Services (e.g. searching for
          and applying for job) you have consented for us to use your
          information to:
        </p>
        <br />
        <ul>
          <li>Check for appropriate usage of the Services</li>
          <li>Customize and/or personalize your communications experience</li>
          <li>Better respond to your customer service and support inquiries</li>
          <li>
            Communicate with you about your purchase, account information, or
            customer service needs
          </li>
          <li>
            Communicate with you about our products and events, and for other
            promotional purposes
          </li>
          <li>
            Communicate with you about your requests for references or
            additional discussions
          </li>
          <li>
            Display targeted advertising, announcements, sponsorships, or other
            messages
          </li>
          <li>
            Notify of new product releases and features, answer questions from
            contact or feedback pages
          </li>
          <li>Improve our business and internal operations</li>
          <li>Administer surveys or other features</li>
          <li>Improve our software and content</li>
          <li>Allow you to create an account</li>
          <li>Notify you about updates to the Services</li>
          <li>Contact you for marketing information</li>
          <li>
            Create and manage a profile for you based on information that you
            have provided to us
          </li>
          <li>To manage payments and charges</li>
          <li>To provide you with the services you have requested</li>
        </ul>
        <br />
        <p>
          We also use Google Analytics cookies to collect information about how
          visitors use our website. We use the information to compile reports
          and to help us improve the Services. You can opt out of Google
          Analytics here:{' '}
          <a
            style={{ color: '#19918A' }}
            href="https://support.google.com/analytics/answer/181881?hl=en&ref_topic=2919631"
          >
            https://support.google.com/analytics/answer/181881?hl=en&ref_topic=2919631
          </a>
        </p>
        <br />
        <p>
          We may use your personal information to communicate with you directly
          or indirectly through affiliated suppliers by email and telephone to
          provide confirmation emails when a purchase has been completed, to
          inform you with any changes or issues with a purchase you have made
          and for providing marketing related information and other updates. You
          may opt out of receiving newsletters and other updates. If you decide
          to opt out, your services and experience may degrade.
        </p>
        <br />
        <h5>
          <strong>SHARING INFORMATION</strong>
        </h5>
        <br />
        <p>
          <strong>Service providers</strong>. In order to operate our business,
          we may transfer Personal Data to our suppliers and preferred service
          providers who use that information to help us operate our business.
          For example, providers may provide credit card processing, data
          management, email distribution, market research, information analysis,
          and promotions management.
        </p>
        <br />
        <p>
          <strong>Payment processors</strong>. Any payment card information you
          use to make a purchase within the Services is collected and processed
          directly by our payment processors, such as Stripe. The current
          payment processor for the Services is Stripe. Stripe may use your data
          in accordance with its privacy policy,{' '}
          <a style={{ color: '#19918A' }} href="https://stripe.com/privacy">
            https://stripe.com/privacy
          </a>
          .
        </p>
        <br />
        <p>
          <strong>Other users and third parties</strong>. We may enable other
          users of our Services and third parties to view or receive limited
          information from your profile or information that you submit (e.g. if
          you are a job seeker and you send your resume to a potential
          employer). We may provide information to other companies for purposes
          such as reporting and marketing. We may sell or transfer aggregate or
          deidentified information to third parties, including summary or
          aggregated anonymous information about all or sub -groups of users of
          our site.
        </p>
        <br />
        <p>
          <strong>Marketing partners</strong>. From time to time, we might
          establish a business relationship with selected persons or companies
          with whom we may share and cross-reference information, including
          personally identifiable information about you that will enable such
          persons or entities to contact you regardin g products and services
          that may be of interest to you. If you prefer that we do not share any
          information with these companies, please send an email to{' '}
          <a
            style={{ color: '#19918A' }}
            href="mailto:info@findajobusa.net?bcc=greg@hamparproductions.com"
          >
            info@findajobusa.net
          </a>{' '}
          to update your privacy settings.
        </p>
        <br />
        <p>
          <strong>Affiliates</strong>. Although we don’t currently have
          affiliates, we may in the future. If we do, we may provide your
          information to such wholly-owned companies and affiliates, which may
          be outside the United States.
        </p>
        <br />
        <p>
          <strong>Business transferees</strong>. We may also transfer your
          personal information to third parties in connection with business
          transactions (or negotiations of or due diligence for such
          transactions) including any merger, acquisition, reorganization, or
          change in capital structure or sale of all or substantially all of the
          assets of USA Job Solutions.
        </p>
        <br />
        <p>
          <strong>Professional advisors</strong>. We may disclose your personal
          information to professional advisors, such as lawyers, bankers,
          auditors, and insurers, where necessary in the course of the
          professional services that they render to us.
        </p>
        <br />
        <p>
          <strong>Authorities and others</strong>. We may disclose specific
          information upon governmental request, in response to a court order,
          when required by law, to enforce our policies, or to protect our or
          others’ rights, property, or safety. We may also share information
          with companies assisting in fraud protection or investigation. We do
          not provide Personal Data to these agencies or companies for marketing
          or commercial purposes.
        </p>
        <br />
        <p>
          <strong>With your consent</strong>. We may share your Personal Data
          for any other purpose or to a third party where you have expressly
          consented to such disclosure or you have otherwise authorized by
          electing to participate in a certain feature or function available
          through the Services.
        </p>
        <br />
        <h5>
          <strong>PROTECTING YOUR INFORMATION</strong>
        </h5>
        <br />
        <p>
          We employ technical, organizational and physical safeguards that are
          designed to protect the personal information we collect. However,
          security risk is inherent in all internet and information
          technologies, and we cannot guarantee the security of your personal
          information.
        </p>
        <br />
        <h5>
          <strong>NAVIGATION</strong>
        </h5>
        <br />
        <p>
          We collect information about your use of our Services using cookies,
          log files, and techniques used to track who is reading a web page or
          e-mail, when, and from what computer, such as web bugs, web beacons,
          or pixel tags.
        </p>
        <br />
        <p>
          A cookie is a small removable data file that is stored by your web
          browser on your computer. Cookies allow us to enhance and personalize
          your online browsing and shopping experience.
        </p>
        <br />
        <p>For example, we use cookies to:</p>
        <br />
        <ul>
          <li>Keep track of purchases</li>
          <li>Keep track of login name/passwords</li>
          <li>Recognize you when you return to our website</li>
          <li>
            Keep track of click stream data (record user-specific information on
            what pages users access or visit)
          </li>
          <li>Tailor web content to users’ interests</li>
          <li>To provide proper functionality in the Services</li>
          <li>Analyze visitor behavior</li>
        </ul>
        <br />
        <p>
          You can choose to have your computer warn you each time a cookie is
          being sent or you can choose to turn off all cookies. You do this
          through your browser settings. Each browser is a little different, so
          look at your browser help menu to learn the correct way to modify your
          cookie settings. Alternatively, you can also learn about how to
          disable cookies in your browser{' '}
          <a style={{ color: '#19918A' }} href="#">
            here
          </a>
          . Please note that disabling cookies may degrade the experience with
          our Services.
        </p>
        <br />
        <p>
          If you decide that you do not want any cookies from USA Job Solutions
          or do not consent to our use of cookies, you may remove our cookies by
          deleting your history and cookies in your browser. Additionally, if
          you do not consent to cookies from USA Job Solutions, you can either
          disable cookies in your browser or not browse the USA Job Solutions
          website.
        </p>
        <br />
        <p>
          Pixel tags, also known as web beacons or web bugs, are tiny graphic
          images (“<strong>GI</strong>”) that are unobtrusively placed on a
          website or in an email. When the HTML code for the GI points to a
          website to retrieve the image, it simultaneously passes information to
          our servers, such as the IP address of the computer that retrieved the
          image, the time and duration the GI was viewed, the type of browser
          that retrieved the image, and previously set cookie values.
        </p>
        <br />
        <p>
          We use GI to help us analyze your online behavior. We may use GI to
          collect information about your visit, including the pages you view,
          the links you click and other actions taken in connection with our
          Service. We also collect certain standard information that your
          browser sends to every website you visit, such as your IP address,
          browser type and language, access times and referring web site
          addresses. We also use GI to allow us to send you email in a format
          you can read and to let us know when you have opened an email message
          from us.
        </p>
        <br />
        <p>
          We also work with other companies who use tracking technologies to
          serve ads on our behalf across the Internet. These companies may
          collect information about your visits to our website and your
          interaction with our communications, including advertising. If you
          would like to opt-out of allowing third party companies to collect
          information about you during your visits to our site, please send an
          email to{' '}
          <a
            style={{ color: '#19918A' }}
            href="mailto:info@findajobusa.net?bcc=greg@hamparproductions.com"
          >
            info@findajobusa.net
          </a>{' '}
          to update your privacy settings.
        </p>
        <br />
        <h6>
          <strong>Data storage; International transfer</strong>
        </h6>
        <br />
        <p>
          All Personal Data collected is stored on our secure servers or those
          of our service providers. Our Services and service providers operate
          globally and your Personal Data may be processed in a country other
          than your own. Although the transmission of information via the
          internet is not completely secure, we always prioritize appropriate
          security measures to protect your Personal Data in accordance with
          this Privacy Policy and applicable law.
        </p>
        <br />
        <h6>
          <strong>Third-party platforms</strong>
        </h6>
        <br />
        <p>
          Our Services may contain links to the websites of one or more third
          parties or contain third-party content. If you interact with any such
          third-party links or content, such third parties’ privacy policies
          will apply. We do not accept and expressly disclaims any
          responsibility or liability for third parties’ policies, practices,
          and actions. Please review all third-party policies before submitting
          any information to such third parties.
        </p>
        <br />
        <h6>
          <strong>Exercising Data Protection Rights</strong>
        </h6>
        <br />
        <p>
          To unsubscribe from an email, please send a request to{' '}
          <a
            style={{ color: '#19918A' }}
            href="mailto:info@findajobusa.net?bcc=greg@hamparproductions.com"
          >
            info@findajobusa.net
          </a>
          .
        </p>
        <br />
        <p>
          To exercise any of your applicable rights under data protection laws,
          please contact us at{' '}
          <a
            style={{ color: '#19918A' }}
            href="mailto:info@findajobusa.net?bcc=greg@hamparproductions.com"
          >
            info@findajobusa.net
          </a>{' '}
          and provide the specific request and relevant information to enable
          the completion of your request. We may request additional information
          from you to help us confirm your identity and ensure your right to
          access the applicable Personal Data (or to exercise any of your other
          rights .) Such security measures are intended to ensure that Personal
          Data is not disclosed to unauthorized persons. We respond to all data
          subject access requests in accordance with applicable data protection
          laws.
        </p>
        <br />
        <p>
          If you are a resident of the European Union, (i) you can object to
          processing of your Personal Data, request to restrict processing of
          your Personal Data, or request portability of your Personal Data at
          the contact information provided above and (ii) if we have processed
          your Personal Data based on your consent, then you can withdraw your
          consent at any time. Withdrawing your consent will not affect the
          lawfulness of any processing conducted prior to withdrawal, nor will
          it affect processing conducted in re liance on lawful grounds other
          than consent. You may have certain Personal Data rights with your
          applicable data protection authority. For more information, please
          contact your local data protection authority (contact details for data
          protection authorities in the European Economic Area, Switzerland, and
          certain non-European countries are{' '}
          <a style={{ color: '#19918A' }} href="#">
            available here
          </a>
          .)
        </p>
        <br />
        <p>
          If you request deletion of your registration information or if you
          unsubscribe from communications, we may maintain information about
          certain transactions or actions for lawful record keeping and
          compliance purposes.
        </p>
        <br />
        <h6>
          <strong>Data retention</strong>
        </h6>
        <br />
        <p>
          Pursuant to applicable law, we will retain your data for as long as
          you continue using our Services and as otherwise necessary to comply
          with our legal obligations.
        </p>
        <br />
        <h6>
          <strong>Minors and Children</strong>
        </h6>
        <br />
        <p>
          Our Services and website are not intended for use by persons under the
          age of 18 years. We do not knowingly collect or solicit Personal Data
          from anyone under the age of 13 or knowingly allow such persons to
          create an account. In the event that we learn that we have collected
          Personal Data of a child under the age of 13, we will delete that data
          to the extent required by applicable law. If you believe that we might
          have any Personal Data from or about a child under 13, please contact
          us at info@findajobusa.net.
        </p>
        <br />
        <h6>
          <strong>Public Form</strong>
        </h6>
        <br />
        <p>
          You should be aware that when you voluntarily disclose any Personal
          Data in a public forum on the website, this information, along with
          any information disclosed in your communication, can be collected and
          used by third parties and may result in unsolicited messages from
          third parties. Such activities are beyond our control and this Privacy
          Policy does not apply to such information. You accept that you, and
          not USA Job Solutions, are responsible for providing such information
          in any public forum. Any submis sions to public areas on our website
          are accepted by you with the understanding that such forums are
          accessible to all third parties. If you do not want your comments or
          any content to be viewed by third parties, you are advised not to make
          any submissions. Please be careful and responsible whenever you're
          online.
        </p>
        <br />
        <h5>
          <strong>UPDATES TO THE PRIVACY POLICY</strong>
        </h5>
        <br />
        <p>
          We reserve the right to change this Privacy Policy at any time. All
          updates and changes made to our Privacy Policy will be posted on this
          page and we encourage you to review our Privacy Policy regularly to
          see and understand all such modifications. When required by law, we
          will provide notice to you of certain changes.
        </p>
        <br />
        <h5>
          <strong>CONTACT INFORMATION</strong>
        </h5>
        <br />
        <p>
          We welcome your questions, comments, and concerns about privacy.
          Please contact us by mail or email at:
        </p>
        <br />
        <p>
          USA Job Solutions Attn: Legal: Privacy Policy; 8772 Enloe Ave. Garden
          Grove, CA. 92844 info@findajobusa.net
        </p>
        <br />
        <br />
        <h5>
          <strong>TERMS OF USE</strong>
        </h5>
        <br />
        <p>
          Please also review our{' '}
          <a href="/terms-of-use" style={{ color: '#19918A' }}>
            Terms of Use
          </a>{' '}
          which provide the legal terms governing the use of the USA Job
          Solutions Services.
        </p>
      </div>
    </Layout>
  )
}

export default PrivacyPolicyPage
