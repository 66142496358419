import { useState, useEffect } from 'react'
import Layout from '../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
} from '@stripe/react-stripe-js'
import { apiPurchasePlan } from '../constants/defines'
import { withAuthorization } from '../session'
import { updateUserMembership } from '../db//user'
import { getPlans } from '../db/plan'
import _ from 'lodash'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Checkout = (props) => {
  const stripe = useStripe()
  const elements = useElements()
  const [cardBrand, setCardBrand] = useState('')

  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [address, setAddress] = useState('')
  const [plan, setPlan] = useState(null)
  const [plans, setPlans] = useState([])

  const user = props.user
  const db = props.firebase.getdb()

  const search = window.location.search
  const params = new URLSearchParams(search)
  const queryPlan = params.get('plan')

  useEffect(() => {
    getPlans(db).then((items) => {
      setPlans([...items])
    })
  }, [])

  useEffect(() => {
    const planItem = _.find(plans, (item) => {
      const planTitle = item.title.toLowerCase()
      return planTitle === queryPlan
    })

    if (!planItem) return
    setPlan({ ...planItem })
  }, [queryPlan, plans])

  const handleSubmit = async (event) => {
    event.preventDefault()

    try {
      const params = {
        plan: plan.title,
        userId: user.uid,
        priceId: plan.priceId,
      }

      const url = apiPurchasePlan
      const response = await fetch(url, {
        method: 'post',
        body: JSON.stringify(params),
      })

      const data = await response.json()
      if (!data.success) {
        //toast.error(data.message);
        return
      }

      const cardNumberElement = elements.getElement(CardNumberElement)

      const { error, paymentIntent } = await stripe.confirmCardPayment(
        data.clientSecret,
        {
          payment_method: {
            card: cardNumberElement,
            billing_details: {
              name,
              email,
              phone,
              address: {
                line1: address,
              },
            },
          },
        },
      )

      if (error) {
        console.error('Error confirming card payment:', error)

        toast.error('Checkout failed')
      } else if (paymentIntent && paymentIntent.status === 'succeeded') {
        console.log('Payment successful!')
        //
        await updateUserMembership(db, user.uid, plan.title)

        window.location.href = '/payment-complete'
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleCardNumberChange = (event) => {
    if (event.brand) {
      setCardBrand(event.brand)
    }
  }

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding: '10px 12px',
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  }

  return (
    <>
      <Layout>
        <div>
          <section className="section-box">
            <div className="breacrumb-cover">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h2 className="mb-10">Checkout</h2>
                  </div>
                  <div className="col-lg-6 text-lg-end">
                    <ul className="breadcrumbs mt-40">
                      <li>
                        <a className="home-icon" href="#">
                          Home
                        </a>
                      </li>
                      <li>Checkout</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-box mt-90">
            <div className="container">
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div
                    className="col-lg-8 col-md-12 col-sm-12 wow animate__animated animate__fadeInUp"
                    data-wow-delay=".1s"
                  >
                    <div className="card-grid-border text-start hover-up wow animate__animated animate__fadeIn">
                      <h3 className="mb-15 wow text-center animate__animated animate__fadeInUp">
                        Billing Information
                      </h3>
                      <div className="form-group">
                        <label className="form-label" htmlFor="input-1">
                          Name
                        </label>
                        <input
                          className="form-control"
                          id="input-1"
                          type="text"
                          required
                          name="name"
                          placeholder="Name"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="input-2">
                          Billing Address
                        </label>
                        <input
                          className="form-control"
                          id="input-2"
                          type="text"
                          required
                          name="address"
                          placeholder="Billing Address"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="input-3">
                          Email
                        </label>
                        <input
                          className="form-control"
                          id="input-3"
                          type="email"
                          required
                          name="email"
                          placeholder="Email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label" htmlFor="input-4">
                          Phone Number
                        </label>
                        <input
                          className="form-control"
                          id="input-4"
                          type="text"
                          required
                          name="phone"
                          placeholder="Phone Number"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </div>
                      <div className="form-group">
                        <label className="form-label">Card Number</label>
                        <div className="card-number-container">
                          <CardNumberElement
                            className="form-control card-number-element"
                            options={CARD_ELEMENT_OPTIONS}
                            onChange={handleCardNumberChange}
                          />
                          {cardBrand && cardBrand !== 'unknown' && (
                            <img
                              className="card-brand-logo"
                              src={`/assets/imgs/brands/${cardBrand}-logo.png`}
                              alt={`${cardBrand} logo`}
                            />
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div
                          className="col-lg-6 col-md-12 col-sm-12 wow animate__animated animate__fadeInUp"
                          data-wow-delay=".1s"
                        >
                          <div className="form-group">
                            <label className="form-label">Expiration</label>
                            <CardExpiryElement
                              className="form-control"
                              options={CARD_ELEMENT_OPTIONS}
                            />
                          </div>
                        </div>
                        <div
                          className="col-lg-6 col-md-12 col-sm-12 wow animate__animated animate__fadeInUp"
                          data-wow-delay=".1s"
                        >
                          <div className="form-group">
                            <label className="form-label">CVC</label>
                            <CardCvcElement
                              className="form-control"
                              options={CARD_ELEMENT_OPTIONS}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {plan && (
                    <div
                      className="col-lg-4 col-md-12 col-sm-12 wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">
                        <h3 className="text-center mb-55 wow animate__animated animate__fadeInUp">
                          Summary
                        </h3>
                        <div className="form-group d-flex">
                          <label className="form-label">
                            <h4>Selected Plan: {plan.title}</h4>
                          </label>
                        </div>
                        <div className="form-group d-flex">
                          <label className="form-label">
                            <h4>Total: ${plan.price}</h4>
                          </label>
                        </div>

                        <button
                          className="btn btn-default btn-shadow hover-up w-100"
                          type="submit"
                          disabled={!stripe}
                          name="checkout"
                        >
                          Proceed to check out
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </form>
            </div>
          </section>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(Checkout)
