import { Link } from 'react-router-dom'
import Layout from '../components/Layout/Layout'
import TestimonialSlider1 from './../components/sliders/Testimonial1'
import FeaturedSlider from './../components/sliders/Featured'

export default function Pricing() {
  return (
    <>
      <Layout>
        <div>
          <section className="section-box">
            <div className="breacrumb-cover">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h2 className="mb-10">Pricing</h2>
                    <p className="font-lg color-text-paragraph-2">
                      Pricing built to suits teams of all sizes.
                    </p>
                  </div>
                  <div className="col-lg-6 text-lg-end">
                    <ul className="breadcrumbs mt-40">
                      <li>
                        <a className="home-icon" href="#">
                          Home
                        </a>
                      </li>
                      <li>Pricing</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="section-box mt-90">
            <div className="container">
              <h2 className="text-center mb-15 wow animate__animated animate__fadeInUp">
                Pricing Table
              </h2>
              <div className="font-lg color-text-paragraph-2 text-center wow animate__animated animate__fadeInUp">
                Choose The Best Plan That’s For You
              </div>
              <div className="max-width-price">
                <div className="block-pricing mt-70">
                  <div className="row">
                    <div
                      className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <div className="box-pricing-item">
                        <h3>Basic</h3>
                        <div className="box-info-price">
                          <span className="text-price color-brand-2">$299</span>
                        </div>
                        <div className="border-bottom mb-30"></div>
                        <ul className="list-package-feature">
                          <li>1 posting</li>
                        </ul>
                        <div>
                          <a
                            className="btn btn-border"
                            href="/checkout?plan=basic"
                          >
                            Choose plan
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                      data-wow-delay=".2s"
                    >
                      <div className="box-pricing-item">
                        <h3>Plus</h3>
                        <div className="box-info-price">
                          <span className="text-price for-month display-month">
                            $1,196
                          </span>
                        </div>
                        <div className="border-bottom mb-30"></div>
                        <ul className="list-package-feature">
                          <li>5 postings</li>
                        </ul>
                        <div>
                          <a
                            className="btn btn-border"
                            href="/checkout?plan=plus"
                          >
                            Choose plan
                          </a>
                        </div>
                      </div>
                    </div>
                    <div
                      className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                      data-wow-delay=".4s"
                    >
                      <div className="box-pricing-item">
                        <h3>Premium</h3>
                        <div className="box-info-price">
                          <span className="text-price for-month display-month">
                            $2,000
                          </span>
                        </div>
                        <div className="border-bottom mb-30"></div>
                        <ul className="list-package-feature">
                          <li>10 postings a month</li>
                        </ul>
                        <div>
                          <a
                            className="btn btn-border"
                            href="/checkout?plan=premium"
                          >
                            Choose plan
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/*<section className="section-box mt-90 mb-50">*/}
          {/*  <div className="container">*/}
          {/*    <h2 className="text-center mb-15 wow animate__animated animate__fadeInUp">*/}
          {/*      Frequently Asked Questions*/}
          {/*    </h2>*/}
          {/*    <div className="font-lg color-text-paragraph-2 text-center wow animate__animated animate__fadeInUp">*/}
          {/*      Aliquam a augue suscipit, luctus neque purus ipsum neque dolor*/}
          {/*      <br className="d-none d-lg-block" /> primis a libero tempus,*/}
          {/*      blandit and cursus varius and magnis sapien*/}
          {/*    </div>*/}
          {/*    <div className="row mt-50">*/}
          {/*      <div className="col-lg-6 col-md-12 col-sm-12">*/}
          {/*        <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">*/}
          {/*          <h4 className="mb-20">*/}
          {/*            I have promotional or discount code?*/}
          {/*          </h4>*/}
          {/*          <p className="font-sm mb-20 color-text-paragraph">*/}
          {/*            Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
          {/*            Duis id nibh vitae quam blandit venenatis. Duis vehicula*/}
          {/*            magna ut neque tempus tristique. Nam venenatis turpis*/}
          {/*            euismod arcu aliquet finibus. Vivamus nec vulputate ex,*/}
          {/*            vitae condimentum ante. Suspendisse metus metus, laoreet*/}
          {/*            nec arcu vel, venenatis cursus libero.*/}
          {/*          </p>*/}
          {/*          <a className="link-arrow" href="#">*/}
          {/*            Keep Reading*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="col-lg-6 col-md-12 col-sm-12">*/}
          {/*        <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">*/}
          {/*          <h4 className="mb-20">*/}
          {/*            Where is my order? Quisque molestie*/}
          {/*          </h4>*/}
          {/*          <p className="font-sm mb-20 color-text-paragraph">*/}
          {/*            Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
          {/*            Duis id nibh vitae quam blandit venenatis. Duis vehicula*/}
          {/*            magna ut neque tempus tristique. Nam venenatis turpis*/}
          {/*            euismod arcu aliquet finibus. Vivamus nec vulputate ex,*/}
          {/*            vitae condimentum ante. Suspendisse metus metus, laoreet*/}
          {/*            nec arcu vel, venenatis cursus libero.*/}
          {/*          </p>*/}
          {/*          <a className="link-arrow" href="#">*/}
          {/*            Keep Reading*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="col-lg-6 col-md-12 col-sm-12">*/}
          {/*        <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">*/}
          {/*          <h4 className="mb-20">*/}
          {/*            How can I return an item purchased online?*/}
          {/*          </h4>*/}
          {/*          <p className="font-sm mb-20 color-text-paragraph">*/}
          {/*            Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
          {/*            Duis id nibh vitae quam blandit venenatis. Duis vehicula*/}
          {/*            magna ut neque tempus tristique. Nam venenatis turpis*/}
          {/*            euismod arcu aliquet finibus. Vivamus nec vulputate ex,*/}
          {/*            vitae condimentum ante. Suspendisse metus metus, laoreet*/}
          {/*            nec arcu vel, venenatis cursus libero.*/}
          {/*          </p>*/}
          {/*          <a className="link-arrow" href="#">*/}
          {/*            Keep Reading*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="col-lg-6 col-md-12 col-sm-12">*/}
          {/*        <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">*/}
          {/*          <h4 className="mb-20">*/}
          {/*            What are the delivery types you use?*/}
          {/*          </h4>*/}
          {/*          <p className="font-sm mb-20 color-text-paragraph">*/}
          {/*            Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
          {/*            Duis id nibh vitae quam blandit venenatis. Duis vehicula*/}
          {/*            magna ut neque tempus tristique. Nam venenatis turpis*/}
          {/*            euismod arcu aliquet finibus. Vivamus nec vulputate ex,*/}
          {/*            vitae condimentum ante. Suspendisse metus metus, laoreet*/}
          {/*            nec arcu vel, venenatis cursus libero.*/}
          {/*          </p>*/}
          {/*          <a className="link-arrow" href="#">*/}
          {/*            Keep Reading*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="col-lg-6 col-md-12 col-sm-12">*/}
          {/*        <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">*/}
          {/*          <h4 className="mb-20">How can I pay for my purchases?</h4>*/}
          {/*          <p className="font-sm mb-20 color-text-paragraph">*/}
          {/*            Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
          {/*            Duis id nibh vitae quam blandit venenatis. Duis vehicula*/}
          {/*            magna ut neque tempus tristique. Nam venenatis turpis*/}
          {/*            euismod arcu aliquet finibus. Vivamus nec vulputate ex,*/}
          {/*            vitae condimentum ante. Suspendisse metus metus, laoreet*/}
          {/*            nec arcu vel, venenatis cursus libero.*/}
          {/*          </p>*/}
          {/*          <a className="link-arrow" href="#">*/}
          {/*            Keep Reading*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*      <div className="col-lg-6 col-md-12 col-sm-12">*/}
          {/*        <div className="card-grid-border hover-up wow animate__animated animate__fadeIn">*/}
          {/*          <h4 className="mb-20">*/}
          {/*            What are the delivery types you use?*/}
          {/*          </h4>*/}
          {/*          <p className="font-sm mb-20 color-text-paragraph">*/}
          {/*            Lorem ipsum dolor sit amet, consectetur adipiscing elit.*/}
          {/*            Duis id nibh vitae quam blandit venenatis. Duis vehicula*/}
          {/*            magna ut neque tempus tristique. Nam venenatis turpis*/}
          {/*            euismod arcu aliquet finibus. Vivamus nec vulputate ex,*/}
          {/*            vitae condimentum ante. Suspendisse metus metus, laoreet*/}
          {/*            nec arcu vel, venenatis cursus libero.*/}
          {/*          </p>*/}
          {/*          <a className="link-arrow" href="#">*/}
          {/*            Keep Reading*/}
          {/*          </a>*/}
          {/*        </div>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</section>*/}
        </div>
      </Layout>
    </>
  )
}
