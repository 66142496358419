import { Collections } from '../constants/defines'
import _ from 'lodash'
import moment from 'moment'
import {
  collection,
  query,
  where,
  getDocs,
  orderBy,
  doc,
  updateDoc,
  addDoc,
  setDoc,
  getDoc,
  deleteDoc,
  onSnapshot,
} from 'firebase/firestore'

const deleteUser = (db, docId) => {
  return new Promise((resolve, reject) => {
    deleteDoc(doc(db, Collections.USERS, docId))
      .then(() => {
        console.log('User successfully deleted!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error deleting User: ', error)
        reject()
      })
  })
}

const createUser = (db, uid, data) => {
  const item = { ...data, uid }
  item.createdAt = new Date()
  item.signedIn = new Date()
  item.credit = 0
  item.isAdmin = false
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, uid)
    setDoc(docRef, item)
      .then(() => {
        console.log('New User created with ID: ', uid)
        resolve(uid)
      })
      .catch((error) => {
        console.error('Error adding User: ', error)
        reject()
      })
  })
}

const getUser = (db, uid) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, uid)
    getDoc(docRef).then((docSnap) => {
      if (docSnap.exists()) {
        const data = { ...docSnap.data() }
        resolve(data)
      } else {
        resolve(null)
      }
    })
  })
}

const updateUser = (db, docId, item) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, docId)
    updateDoc(docRef, {
      ...item,
    })
      .then(() => {
        console.log('User Data successfully updated!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User Data: ', error)
        reject()
      })
  })
}

const updateUserignedIn = (db, docId) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, docId)
    updateDoc(docRef, {
      signedIn: new Date(),
    })
      .then(() => {
        console.log('User Sign successfully updated!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User Sign: ', error)
        reject()
      })
  })
}

const updateUserMembership = (db, docId, plan) => {
  return new Promise((resolve, reject) => {
    const docRef = doc(db, Collections.USERS, docId)
    const membershipDate = new Date()

    const currentDate = moment().format('YYYY-MM-DD')
    const membershipStartDate = moment(currentDate).toDate()
    const membershipEndDate = moment(currentDate)
      .add(1, 'months')
      .subtract(1, 'day')
      .toDate()

    updateDoc(docRef, {
      membershipDate,
      membershipStartDate,
      membershipEndDate,
      plan,
    })
      .then(() => {
        console.log('User Membership successfully updated!')
        resolve()
      })
      .catch((error) => {
        // The document probably doesn't exist.
        console.error('Error updating User Membership: ', error)
        reject()
      })
  })
}

export {
  createUser,
  getUser,
  deleteUser,
  updateUser,
  updateUserignedIn,
  updateUserMembership,
}
