import React from 'react'
import AuthUserContext from './context'
import { withFirebase } from '../firebase'
import { getUser } from '../db/user'

const withAuthentication = (Component) => {
  class WithAuthentication extends React.Component {
    constructor(props) {
      super(props)

      this.state = {
        authUser: null,
      }
    }

    componentDidMount() {
      const db = this.props.firebase.getdb()
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (!authUser) {
            this.setState({ authUser: null })
            return
          }
          getUser(db, authUser.uid)
            .then((user) => {
              this.setState({ authUser: { ...user } })
            })
            .catch(() => {
              this.setState({ authUser: null })
            })
        },
      )
    }

    componentWillUnmount() {
      this.listener()
    }

    setAuthUser = (user) => {
      this.setState({ authUser: user });
    }

    render() {
      return (
        <AuthUserContext.Provider value={{ user: this.state.authUser, setUser: this.setAuthUser }}>
          <Component {...this.props} />
        </AuthUserContext.Provider>
      )
    }
  }

  return withFirebase(WithAuthentication)
}

export default withAuthentication
