import { useCallback, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { CITIES_BY_STATE } from '../../constants/citiesByState'
import { getIndustryByKey, getIndustryKeys } from '../../constants/Industries'

const INDUSTRY_KEYS = getIndustryKeys()
const FilterBar = ({
  onChange,
  industryCounters,
  jobPostedCounters,
  disabled,
}) => {
  const [searchParams] = useSearchParams()
  const [city, setCity] = useState(searchParams.get('city') ?? '')
  const [showAllIndustries, setShowAllIndustries] = useState(true)
  const [queryVariables, setQueryVariables] = useState(undefined)
  const [firstTime, setFirstTime] = useState(true)
  const [industries, setIndustries] = useState(
    searchParams.get('industries')?.split(',') ?? [],
  )
  const [jobPosted, setJobPosted] = useState(
    +searchParams.get('posted-day') ?? 0,
  )

  useEffect(() => {
    let queryVariables = []
    if (city !== '') {
      queryVariables['city'] = city
    }

    if (jobPosted) {
      queryVariables['posted-day'] = jobPosted
    }

    if (industries.length > 0) {
      queryVariables['industries'] = industries
    }
    setQueryVariables(queryVariables)
  }, [city, jobPosted, industries])

  const onChangeQuery = useCallback(() => {
    if (firstTime) {
      setFirstTime(false)
      return
    }
    onChange(queryVariables)
  }, [queryVariables])
  useEffect(() => {
    if (queryVariables) {
      onChangeQuery()
    }
  }, [queryVariables])

  const handleIndustryChange = (industry) => {
    const newIndustries = industries.includes(industry)
      ? industries.filter((ind) => ind !== industry)
      : [...industries, industry]

    setIndustries(newIndustries)
  }

  const onShowAllIndustry = () => {
    setShowAllIndustries(!showAllIndustries)
  }

  const onReset = () => {
    setJobPosted(undefined)
    setIndustries([])
    setCity('')
  }

  return (
    <div className={`sidebar-filters ${disabled ? 'filters-disabled' : ''}`}>
      <div
        className="filter-block mb-30 d-flex"
        style={{ justifyContent: 'space-between' }}
      >
        <h5>Advance Filter</h5>
        <span
          className="font-s text-brand-2 hover-up"
          onClick={onReset}
          style={{ cursor: 'pointer' }}
        >
          Reset
        </span>
      </div>

      {searchParams.get('state') && (
        <div className="filter-block mb-30">
          <div className="form-group select-style select-style-icon">
            <select
              className="form-control form-icons select-active pl-40 "
              value={city}
              onChange={(e) => setCity(e.target.value)}
            >
              <option value="">All cities</option>
              {searchParams.get('state') &&
                CITIES_BY_STATE[searchParams.get('state')].map((city) => {
                  return (
                    <option value={city} key={'filter-bar-state-' + city}>
                      {city}
                    </option>
                  )
                })}
            </select>
            <i
              className="fi-rr-marker user-select-none"
              style={{ pointerEvents: 'none' }}
            />
          </div>
        </div>
      )}

      <div className="filter-block mb-30">
        <h6 className="medium-heading mb-10">Job Posted</h6>
        <div className="form-group">
          <ul className="list-checkbox">
            <li>
              <label className="cb-container">
                <input
                  type="checkbox"
                  checked={jobPosted === 0}
                  onChange={() => setJobPosted(0)}
                />
                <span className="text-small active">All</span>
                <span className="checkmark" />
              </label>
              {!!jobPostedCounters[0] && (
                <span
                  className={`number-item ${!jobPostedCounters[0] || jobPostedCounters[0] === 0 || jobPosted === 0 ? '' : 'ni-disabled'}`}
                >
                  {jobPostedCounters[0] ?? 0}
                </span>
              )}
            </li>
            <li>
              <label className="cb-container">
                <input
                  type="checkbox"
                  checked={jobPosted === 1}
                  onChange={() => setJobPosted(1)}
                />
                <span className="text-small">1 day</span>
                <span className="checkmark" />
              </label>
              {!!jobPostedCounters[1] && (
                <span
                  className={`number-item ${jobPosted === 1 ? '' : 'ni-disabled'}`}
                >
                  {jobPostedCounters[1] ?? 0}
                </span>
              )}
            </li>
            <li>
              <label className="cb-container">
                <input
                  type="checkbox"
                  checked={jobPosted === 7}
                  onChange={() => setJobPosted(7)}
                />
                <span className="text-small">7 days</span>
                <span className="checkmark" />
              </label>
              {!!jobPostedCounters[7] && (
                <span
                  className={`number-item ${jobPosted === 7 ? '' : 'ni-disabled'}`}
                >
                  {jobPostedCounters[7] ?? 0}
                </span>
              )}
            </li>
            <li>
              <label className="cb-container">
                <input
                  type="checkbox"
                  checked={jobPosted === 30}
                  onChange={() => setJobPosted(30)}
                />
                <span className="text-small">30 days</span>
                <span className="checkmark" />
              </label>
              {!!jobPostedCounters[30] && (
                <span
                  className={`number-item ${jobPosted === 30 ? '' : 'ni-disabled'}`}
                >
                  {jobPostedCounters[30] ?? 0}
                </span>
              )}
            </li>
          </ul>
        </div>
      </div>

      <div className="filter-block mb-20">
        <h6 className="medium-heading mb-15">Industry</h6>
        <div className="form-group">
          <ul className="list-checkbox">
            {INDUSTRY_KEYS.map((industry, index) => {
              if (!showAllIndustries) {
                if (index > 4)
                  return <div key={industry + 'industry' + index} />
              }
              return (
                <li key={industry + 'industry' + index}>
                  <label className="cb-container">
                    <input
                      type="checkbox"
                      checked={industries.includes(industry)}
                      onChange={() => handleIndustryChange(industry)}
                    />
                    <span className="text-small">
                      {getIndustryByKey(industry).value}
                    </span>
                    <span className="checkmark" />
                  </label>
                  {!!industryCounters && (
                    <span
                      className={`number-item ${(industries.includes(industry) || industries.length === 0) && industryCounters && industryCounters[industry] !== 0 ? '' : 'ni-disabled'}`}
                    >
                      {industryCounters && industryCounters[industry]
                        ? industryCounters[industry]
                        : 0}
                    </span>
                  )}
                </li>
              )
            })}
            <li>
              <label className="cb-container" style={{ paddingLeft: 0 }}>
                <span
                  className="text-small text-paragraph-2"
                  onClick={onShowAllIndustry}
                >
                  <u>{showAllIndustries ? 'Show Less' : 'Show All'}</u>
                </span>
              </label>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default FilterBar
