import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { withFirebase } from '../../firebase'
import Layout from '../../components/Layout/Layout'
import { getJob } from '../../db/job'
import Details from './details'

const JobDetail = (props) => {
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [job, setJob] = useState()

  const db = props.firebase.getdb()

  useEffect(() => {
    getJob(db, id).then((item) => {
      setJob(item)
      setLoading(false)
    })
  }, [])

  if (job === undefined) {
    return null
  }

  return (
    <Layout>
      <section className="section-box mt-50 text-start">
        <div className="container">
          {loading && <span>Loading data...</span>}
          {!loading && <Details data={job} printableId={id} />}
        </div>
      </section>
    </Layout>
  )
}

export default withFirebase(JobDetail)
