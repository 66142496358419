import { initializeApp } from 'firebase/app'
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  updatePassword,
  signInWithPopup,
  GoogleAuthProvider
} from 'firebase/auth'

import { getFirestore, doc, updateDoc, setDoc, collection, getDocs } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { Collections } from '../constants/defines'

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
}

class Firebase {
  constructor() {
    const app = initializeApp(config)
    //firebase.firestore(app);

    this.auth = getAuth(app)    
    this.db = getFirestore(app)
    this.storage = getStorage(app)
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    createUserWithEmailAndPassword(this.auth, email, password)

  doSignInWithEmailAndPassword = (email, password) =>
    signInWithEmailAndPassword(this.auth, email, password)

  doSignOut = () => {
    signOut(this.auth)
  }

  doGoogleLogin = () => {
    const provider = new GoogleAuthProvider()
    signInWithPopup(this.auth, provider)
    .then((result) => {
      const docId = result.user.uid
      const docRef = doc(this.db, Collections.USERS, docId)
      updateDoc(docRef, {
        signedIn: new Date(),
      })
        .then(() => {
          console.log('User Sign successfully updated!')
          localStorage.setItem('currentuser', result.user.email)
          window.location.href = `/profile`;
        })
        .catch((error) => {
          const item = {
            email: result.user.email,
            name: result.user.displayName,
            username: result.user.displayName,
            phone: result.user.phoneNumber,
            uid: result.user.uid,
            credit: 0
          }
          setDoc(docRef, item)
          .then(() => {
            console.log('New User created with ID: ', docId)
            localStorage.setItem('currentuser', result.user.email)
            window.location.href = `/profile`;
          })
          .catch((error) => {
            console.error('Error creating User: ', error)            
          })

        })
    }).catch((error) => {
      console.log('error')            
    })
  }

  doPasswordReset = (email) => sendPasswordResetEmail(this.auth, email)

  doPasswordUpdate = (password) =>
    updatePassword(this.auth.currentUser, password)

  getdb = () => this.db
  getstorage = () => this.storage
  getFirebase = () => this
}

export default Firebase
