import Layout from '../../components/Layout/Layout'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { withAuthorization } from '../../session'
import React, { useContext, useEffect, useState } from 'react'
import FirebaseContext from '../../firebase/context'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { deleteUser, updateUser } from '../../db/user'
import { getPayments } from '../../db/payment'
import { getMyJobs } from '../../db/job'
import moment from 'moment'
import JobCard from '../../components/elements/JobCard'

const Profile = (props) => {
  const [activeIndex, setActiveIndex] = useState(1)
  const [user, setUser] = useState({ ...props.user })
  const [data, setData] = useState({ ...props.user })
  const firebaseContext = useContext(FirebaseContext)
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const tab = parseInt(searchParams.get('tab')) || 1
  const [payments, setPayments] = useState([])
  const [jobs, setJobs] = useState([])
  const db = props.firebase.getdb()
  const tableHeaders = ['Date', 'Name', 'Credits', 'Amount', 'Status']

  const params = useParams()

  useEffect(() => {
    getPayments(db).then((items) => {
      const temp = items.filter((t) => t.userId === user.uid)
      setPayments([...temp])
    })
    getMyJobs(db).then((items) => {
      const temp = items.filter((t) => t.userId === user.uid)
      setJobs([...temp])
    })
  }, [user])

  useEffect(() => {
    handleOnClick(tab)
  }, [tab])

  const renderJobs = () => {
    return (
      <div className="row">
        {jobs &&
          jobs.map((row, i) => {
            return <JobCard job={row} editable />
          })}
      </div>
    )
  }
  const onSubmit = (e) => {
    e.preventDefault()
    updateUser(db, user.uid, data).then(() => {
      //setAuthUser(data);
      toast.success('Update successful!')
    })
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setData({ ...data, [name]: value })
  }

  const handleOnClick = (index) => {
    setActiveIndex(index)
    if (index === 2) {
      getMyJobs(db).then((items) => {
        const temp = items.filter((t) => t.userId === user.uid)
        setJobs([...temp])
      })
    } else if (index === 3) {
      getPayments(db).then((items) => {
        const temp = items.filter((t) => t.userId === user.uid)
        setPayments([...temp])
      })
    }
  }

  const firebase = props.firebase

  const clickSignOut = (event) => {
    event.preventDefault()
    firebaseContext.doSignOut()
  }

  const handleDeleteAccount = () => {
    deleteUser(db, user.uid).then(() => {
      firebaseContext.doSignOut()
      navigate('/')
    })
  }

  return (
    <>
      <Layout user={user} firebase={firebase}>
        <div>
          <section className="section-box mt-50">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-12">
                  <div className="mb-30 text-start">
                    <h3 className="mt-0 color-brand-1">{user.name}</h3>
                    <div className="font-md color-text-paragraph-2">
                      {user.company}
                    </div>
                    <a href="/pricing">
                      <div className="font-md  mt-10 color-brand-2">
                        <strong>{user.credit} </strong>Credits
                      </div>
                    </a>
                  </div>
                  <div className="box-nav-tabs nav-tavs-profile mb-5">
                    <ul className="nav" role="tablist">
                      <li>
                        <a
                          className={`btn btn-border aboutus-icon mb-20 font-sm ${activeIndex === 1 && 'active'}`}
                          onClick={() => handleOnClick(1)}
                        >
                          Profile
                        </a>
                      </li>
                      <li>
                        <a
                          className={`btn btn-border recruitment-icon mb-20 font-sm ${activeIndex === 2 && 'active'}`}
                          onClick={() => handleOnClick(2)}
                        >
                          Job Postings
                        </a>
                      </li>
                      <li>
                        <a
                          className={`btn btn-border coin-icon font-sm mb-20 ${activeIndex === 3 && 'active'}`}
                          onClick={() => handleOnClick(3)}
                        >
                          Billing and Packages
                        </a>
                      </li>
                    </ul>
                    <div className="border-bottom pt-10 pb-10" />
                    <div className="mt-20 mb-20 text-start">
                      <a
                        className="link-red"
                        onClick={clickSignOut}
                        style={{ cursor: 'pointer' }}
                      >
                        Sign&nbsp;Out
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-9 col-md-8 col-sm-12 col-12 mb-50">
                  <div className="content-single">
                    <div className="tab-content">
                      <div
                        className={`tab-pane fade ${activeIndex === 1 && 'show active'}`}
                      >
                        <div className="row form-contact">
                          <form onSubmit={onSubmit}>
                            <div className="col-lg-6 col-md-12">
                              <div className="form-group">
                                <label className="font-sm color-text-mutted mb-10">
                                  Full Name *
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="name"
                                  value={data.name}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="font-sm color-text-mutted mb-10">
                                  Email *
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="email"
                                  value={data.email}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="font-sm color-text-mutted mb-10">
                                  Contact number
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="phone"
                                  value={data.phone}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="font-sm color-text-mutted mb-10">
                                  Company
                                </label>
                                <input
                                  className="form-control"
                                  type="text"
                                  name="company"
                                  value={data.company}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="form-group">
                                <label className="font-sm color-text-mutted mb-10">
                                  Company website
                                </label>
                                <input
                                  className="form-control"
                                  type="url"
                                  name="web"
                                  value={data.web}
                                  onChange={onChange}
                                />
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="font-sm color-text-mutted mb-10">
                                      State
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="state"
                                      value={data.state}
                                      onChange={onChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="font-sm color-text-mutted mb-10">
                                      City
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="city"
                                      value={data.city}
                                      onChange={onChange}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="font-sm color-text-mutted mb-10">
                                      Zip code
                                    </label>
                                    <input
                                      className="form-control"
                                      type="text"
                                      name="zip"
                                      value={data.zip}
                                      onChange={onChange}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom pt-10 pb-10 mb-30" />
                              <h6 className="color-orange mb-20">
                                Change your password
                              </h6>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="font-sm color-text-mutted mb-10">
                                      Password
                                    </label>
                                    <input
                                      className="form-control"
                                      type="password"
                                      defaultValue={123456789}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="form-group">
                                    <label className="font-sm color-text-mutted mb-10">
                                      Re-Password *
                                    </label>
                                    <input
                                      className="form-control"
                                      type="password"
                                      defaultValue={123456789}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="border-bottom pt-10 pb-10" />
                              <div className="box-button mt-15">
                                <button className="btn btn-apply-big font-md font-bold">
                                  Save Changes
                                </button>
                              </div>
                              <div
                                className="box-button mt-15"
                                data-bs-toggle="modal"
                                data-bs-target="#ModalDeleteAccountorm"
                              >
                                <a
                                  className="mt-30 link-red font-sm"
                                  style={{ cursor: 'pointer' }}
                                >
                                  Delete Account
                                </a>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${activeIndex === 2 && 'show active'}`}
                      >
                        {renderJobs()}
                      </div>
                      <div
                        className={`tab-pane fade ${activeIndex === 3 && 'show active'}`}
                      >
                        <div className="row form-contact ">
                          {user.credit > 0 && (
                            <>
                              <h4 className="mb-15 wow animate__animated animate__fadeInUp">
                                You have {user.credit} Credit
                                {user.credit > 1 ? 's' : ''}
                              </h4>
                              <p>Choose the best plan that’s for You:</p>
                            </>
                          )}

                          {user.credit <= 0 && (
                            <>
                              <h4 className="mb-15 wow animate__animated animate__fadeInUp">
                                You have no credits
                              </h4>
                              <p>Please purchase a package to post jobs:</p>
                            </>
                          )}
                          <div className="block-pricing mt-20">
                            <div className="row">
                              <div
                                className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                                data-wow-delay=".1s"
                              >
                                <div className="box-pricing-item">
                                  <h3>Basic</h3>
                                  <div className="box-info-price">
                                    <span className="text-price color-brand-2">
                                      $299
                                    </span>
                                  </div>
                                  <div className="border-bottom mb-30"></div>
                                  <ul className="list-package-feature p-0 mb-30">
                                    <li>1 posting</li>
                                  </ul>
                                  <div>
                                    <a
                                      className="btn btn-border"
                                      href="/checkout?plan=basic"
                                    >
                                      Choose plan
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                                data-wow-delay=".2s"
                              >
                                <div className="box-pricing-item">
                                  <h3>Plus</h3>
                                  <div className="box-info-price">
                                    <span className="text-price for-month display-month">
                                      $1,196
                                    </span>
                                  </div>
                                  <div className="border-bottom mb-30"></div>

                                  <ul className="list-package-feature p-0 mb-30">
                                    <li>5 postings</li>
                                  </ul>
                                  <div>
                                    <a
                                      className="btn btn-border"
                                      href="/checkout?plan=plus"
                                    >
                                      Purchase Plan
                                    </a>
                                  </div>
                                </div>
                              </div>
                              <div
                                className="col-xl-4 col-lg-6 col-md-6 wow animate__animated animate__fadeInUp"
                                data-wow-delay=".4s"
                              >
                                <div className="box-pricing-item">
                                  <h3>Premium</h3>
                                  <div className="box-info-price">
                                    <span className="text-price for-month display-month">
                                      $2,000
                                    </span>
                                  </div>
                                  <div className="border-bottom mb-30"></div>

                                  <ul className="list-package-feature p-0 mb-30">
                                    <li>10 postings a month</li>
                                  </ul>
                                  <div>
                                    <a
                                      className="btn btn-border"
                                      href="/checkout?plan=premium"
                                    >
                                      Purchase Plan
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row form-contact">
                          <h2 className="mt-25 mb-15 wow animate__animated animate__fadeInUp">
                            Recent Charges
                          </h2>
                          {!payments && (
                            <p className="text-muted">No recent purchases</p>
                          )}
                          {payments && payments.length === 0 && (
                            <p className="text-muted">No recent purchases</p>
                          )}
                          {payments && payments.length > 0 && (
                            <table className="ml-15">
                              <thead>
                                {tableHeaders &&
                                  tableHeaders.map((item) => {
                                    return (
                                      <th className="pb-10 pt-10">
                                        <h4>{item}</h4>
                                      </th>
                                    )
                                  })}
                              </thead>
                              <tbody>
                                {payments.map((item) => {
                                  return (
                                    <>
                                      <tr
                                        className="font-lg color-text-paragraph pb-10 pt-10"
                                        style={{
                                          borderBottom: '1px solid #dee2e6',
                                        }}
                                      >
                                        <td className="pb-10 pt-10">
                                          {moment(
                                            item.createdAt
                                              ? item.createdAt.toDate()
                                              : null,
                                          ).format('M/DD/YYYY')}
                                        </td>
                                        <td className="pb-10 pt-10">
                                          {item.price.id ===
                                          'price_1P6gbeIyyiDigXwRYaTy5SWr'
                                            ? 'Basic Plan'
                                            : item.price.id ===
                                                'price_1P6gdgIyyiDigXwR3aLkvniI'
                                              ? 'Plus Plan'
                                              : 'Premium Plan'}
                                        </td>
                                        <td className="pb-10 pt-10">
                                          {item.credit} credit
                                        </td>
                                        <td className="pb-10 pt-10">
                                          $
                                          {parseFloat(item.price.unit_amount) /
                                            100}
                                        </td>
                                        <td className="pb-10 pt-10">
                                          {item.paymentIntentId !== null
                                            ? 'Paid'
                                            : 'Pending'}
                                        </td>
                                      </tr>
                                    </>
                                  )
                                })}
                              </tbody>
                            </table>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
        <div
          className="modal fade"
          id="ModalDeleteAccountorm"
          tabIndex={-1}
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg mt-200">
            <div className="modal-content apply-job-form">
              <button
                className="btn-close"
                type="button"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="modal-body pl-30 pr-30 pt-50">
                <div className="text-center">
                  <h4 className="mt-10 mb-5 text-brand-1">
                    Are you sure you want to delete the account?
                  </h4>
                </div>
                <div className="row mt-40">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <button
                        className="btn btn-default hover-up w-100"
                        type="button"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        No
                      </button>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <button
                        className="btn btn-border hover-up w-100"
                        name="Yes"
                        onClick={handleDeleteAccount}
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      >
                        Yes
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

const condition = (authUser) => !!authUser

export default withAuthorization(condition)(Profile)
