import React, { useState } from 'react'
import BackToTop from '../elements/BackToTop'
import Footer from './Footer'
import Header from './Header'
import Sidebar from './Sidebar'

const Layout = ({ children, user, firebase }) => {
  const [openClass, setOpenClass] = useState('')

  const handleOpen = () => {
    document.body.classList.add('mobile-menu-active')
    setOpenClass('sidebar-visible')
  }

  const handleRemove = () => {
    if (openClass === 'sidebar-visible') {
      setOpenClass('')
      document.body.classList.remove('mobile-menu-active')
    }
  }
  return (
    <>
      <div
        className="body-overlay-1"
        onClick={handleRemove}
        onScroll={(e) => {
          console.log('on Scroll')
          console.log(e)
        }}
      />
      <Sidebar openClass={openClass} />
      <Header
        user={user}
        firebase={firebase}
        handleOpen={handleOpen}
        handleRemove={handleRemove}
        openClass={openClass}
      />
      <main className="main">{children}</main>
      <Footer />
      <BackToTop />
    </>
  )
}

export default Layout
