import Layout from '../components/Layout/Layout'
import { Link, useNavigate } from 'react-router-dom'
import { withFirebase } from '../firebase'
import { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { createUser } from '../db/user'

const INITIAL_STATE = {
  name: '',
  email: '',
  company: '',
  username: '',
  phone: '',
  country: '',
  state: '',
  city: '',
  zip: '',
  web: '',
}

const Register = (props) => {
  const [user, setUser] = useState({ ...INITIAL_STATE })
  const [password, setPassword] = useState('')
  const [confirmpassword, setConfirmPassword] = useState('')
  const [agree, setAgree] = useState(false)

  const db = props.firebase.getdb()

  const onSubmit = (e) => {
    e.preventDefault()

    if (password !== confirmpassword) {
      toast.error('Passwords do NOT match!')
      return
    }

    if (!agree) {
      toast.error('Do you agree?')
      return
    }
    props.firebase
      .doCreateUserWithEmailAndPassword(user.email, password)
      .then((authUser) => {
        createUser(db, authUser.user.uid, user).then((userid) => {
          console.log(userid)
          toast.success('Register successful!')
          window.location.href = '/profile'
        })
      })
      .catch((error) => {
        console.log(error)
        toast.error(error)
      })
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setUser({ ...user, [name]: value })
  }

  const onChangePassword = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    if (name === 'password') setPassword(value)
    else setConfirmPassword(value)
  }

  const onChangeAgree = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setAgree(value)
  }

  const onGoogleLogin = (e) => {
    e.preventDefault()
    props.firebase.doGoogleLogin()
  }

  return (
    <>
      <Layout>
        <section className="pt-100 login-register">
          <div className="container">
            <div className="row login-register-cover">
              <div className="col-lg-4 col-md-6 col-sm-12 mx-auto">
                <div className="text-center">
                  <p className="font-sm text-brand-2">Register </p>
                  <h2 className="mt-10 mb-5 text-brand-1">
                    Start for free Today
                  </h2>
                  <p className="font-sm text-muted mb-30">
                    Access to all features. No credit card required.
                  </p>
                  <button
                    className="btn social-login hover-up mb-20"
                    onClick={onGoogleLogin}
                  >
                    <img
                      src="assets/imgs/template/icons/icon-google.svg"
                      alt="jobbox"
                    />
                    <strong>Sign up with Google</strong>
                  </button>
                  <div className="divider-text-center">
                    <span>Or continue with</span>
                  </div>
                </div>
                <form
                  className="login-register text-start mt-20"
                  onSubmit={onSubmit}
                >
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-1">
                      Full Name *
                    </label>
                    <input
                      className="form-control"
                      id="input-1"
                      type="text"
                      required
                      name="name"
                      placeholder="Steven Job"
                      value={user.name}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-1">
                      Company Name
                    </label>
                    <input
                      className="form-control"
                      id="input-1"
                      type="text"
                      required
                      name="company"
                      placeholder="Company"
                      value={user.company}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-2">
                      Email *
                    </label>
                    <input
                      className="form-control"
                      id="input-2"
                      type="email"
                      required
                      name="email"
                      placeholder="stevenjob@gmail.com"
                      value={user.email}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-3">
                      Username *
                    </label>
                    <input
                      className="form-control"
                      id="input-3"
                      type="text"
                      required
                      name="username"
                      placeholder="stevenjob"
                      value={user.username}
                      onChange={onChange}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-4">
                      Password *
                    </label>
                    <input
                      className="form-control"
                      id="input-4"
                      type="password"
                      required
                      name="password"
                      placeholder="************"
                      value={password}
                      onChange={onChangePassword}
                    />
                  </div>
                  <div className="form-group">
                    <label className="form-label" htmlFor="input-5">
                      Re-Password *
                    </label>
                    <input
                      className="form-control"
                      id="input-5"
                      type="password"
                      required
                      name="confirmpassword"
                      placeholder="************"
                      value={confirmpassword}
                      onChange={onChangePassword}
                    />
                  </div>
                  <div className="login_footer form-group d-flex justify-content-between">
                    <label className="cb-container">
                      <input
                        type="checkbox"
                        name="agree"
                        onChange={onChangeAgree}
                      />
                      <span className="text-small">
                        By clicking Register button, you agree our terms and
                        policy
                      </span>
                      <span className="checkmark" />
                    </label>
                  </div>
                  <div className="form-group">
                    <button
                      className="btn btn-brand-2 hover-up w-100"
                      type="submit"
                    >
                      Sign&nbsp;Up
                    </button>
                  </div>
                  <div className="text-muted">
                    Already Have an account?
                    <Link className="text-brand-2" to="/login">
                      &nbsp;Sign&nbsp;in
                    </Link>
                  </div>
                </form>
                <ToastContainer
                  position="bottom-center"
                  autoClose={5000}
                  hideProgressBar={false}
                  newestOnTop={false}
                  closeOnClick
                  rtl={false}
                  pauseOnFocusLoss
                  draggable
                  pauseOnHover
                  theme="light"
                />
              </div>
              <div className="img-1 d-none d-lg-block">
                <img
                  className="shape-1 z-index-negative"
                  src="assets/imgs/page/login-register/img-51.svg"
                  alt="JobBox"
                />
              </div>
              <div className="img-3 z-index-negative">
                <img
                  src="assets/imgs/page/login-register/eagle.svg"
                  alt="JobBox"
                />
              </div>
            </div>
          </div>
        </section>
      </Layout>
    </>
  )
}

export default withFirebase(Register)
