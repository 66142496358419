import PerfectScrollbar from 'react-perfect-scrollbar'
import 'react-perfect-scrollbar/dist/css/styles.css'
import React, { useState, useEffect, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import FirebaseContext from '../../firebase/context'
import AuthUserContext from '../../session/context'

const Sidebar = ({ openClass }) => {
  const firebaseContext = useContext(FirebaseContext)
  const { user } = useContext(AuthUserContext)
  const navigate = useNavigate()

  const [isActive, setIsActive] = useState({
    status: false,
    key: '',
  })

  const handleToggle = (key) => {
    if (isActive.key === key) {
      setIsActive({
        status: false,
      })
    } else {
      setIsActive({
        status: true,
        key,
      })
    }
  }

  const clickPostJob = (event) => {
    event.preventDefault()
    navigate('/job')

  }

  const clickSignOut = (event) => {
    event.preventDefault()
    firebaseContext.doSignOut()
  }

  return (
    <>
      <div
        className={`mobile-header-active mobile-header-wrapper-style perfect-scrollbar ${openClass}`}
      >
        <div className="mobile-header-wrapper-inner">
          <div className="mobile-header-content-area">
            <div className="perfect-scroll">
              <div className="mobile-menu-wrap mobile-header-border">
                {/* mobile menu start*/}
                <div className={'fix-top'} />
                <nav>
                  <ul className="mobile-menu font-heading">
                    <li>
                      <Link to="/jobs">Find a Job</Link>
                    </li>

                    <li>
                      <Link to="/pricing">Pricing Plans</Link>
                    </li>
                    <li>
                      <Link to="/about">About</Link>
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="mobile-account">
                <h6 className="mb-10">Your Account</h6>
                <ul className="mobile-menu font-heading">
                  <li>
                    <Link to="/profile">Profile</Link>
                  </li>
                  <li>
                    <button
                      type="button"
                      aria-label="PostJob"
                      onClick={clickPostJob}
                      className="btn btn-default"
                    >
                      Post&nbsp;a&nbsp;Job
                    </button>
                  </li>
                  {user && (
                    <li>
                      <a
                        className="link-red"
                        onClick={clickSignOut}
                        style={{ cursor: 'pointer' }}
                      >
                        Sign&nbsp;Out
                      </a>
                    </li>
                  )}
                </ul>
              </div>
              <div className="site-copyright">
                Copyright 2024 © FindaJobUSA.{' '}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar
