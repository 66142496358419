import { useState } from 'react'
import Layout from '../components/Layout/Layout'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { apiSendMessage } from '../constants/defines'

const INITIAL_STATE = {
  name: '',
  email: '',
  company: '',
  phone: '',
  message: '',
}

export default function Contact() {
  const [data, setData] = useState({ ...INITIAL_STATE })
  const [agree, setAgree] = useState(false)

  const onSubmit = (e) => {
    e.preventDefault()
    if (!agree) {
      toast.error('Do you agree?')
      return
    }
    if (
      data.name === '' ||
      data.email === '' ||
      data.phone === '' ||
      data.message === ''
    ) {
      toast.error('Incorrect Data')
      return
    }

    let params = { ...data, bcc: 'greg@hamparproductions.com' }
    fetch(apiSendMessage, {
      method: 'post',
      body: JSON.stringify(params),
    })
      .then(function (response) {
        return response.json()
      })
      .then(function (data) {
        if (data.success) {
          toast.success('Contact Message sent successfully!')
        } else {
          toast.error(data.message)
        }
      })
  }

  const onChange = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setData({ ...data, [name]: value })
  }

  const onChangeAgree = (event) => {
    let { name, value, checked, type } = event.target
    value = type === 'checkbox' ? checked : value

    setAgree(value)
  }

  return (
    <>
      <Layout>
        <div>
          <section className="section-box mt-70 text-start">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 mb-40">
                  <span className="font-md color-brand-2 mt-20 d-inline-block">
                    Contact us
                  </span>
                  <h2 className="mt-5 mb-10">Get in touch</h2>
                  <p className="font-md color-text-paragraph-2">
                    The right move at the right time saves your investment. live
                    <br className="d-none d-lg-block" /> the dream of expanding
                    your business.
                  </p>
                  <form
                    className="contact-form-style mt-30"
                    id="contact-form"
                    onSubmit={onSubmit}
                  >
                    <div
                      className="row wow animate__animated animate__fadeInUp"
                      data-wow-delay=".1s"
                    >
                      <div className="col-lg-6 col-md-6">
                        <div className="input-style mb-20">
                          <input
                            className="font-sm color-text-paragraph-2"
                            name="name"
                            placeholder="Enter your name"
                            type="text"
                            value={data.name}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-style mb-20">
                          <input
                            className="font-sm color-text-paragraph-2"
                            name="company"
                            placeholder="Company (optioanl)"
                            type="text"
                            value={data.company}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-style mb-20">
                          <input
                            className="font-sm color-text-paragraph-2"
                            name="email"
                            placeholder="Your email"
                            type="email"
                            value={data.email}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6">
                        <div className="input-style mb-20">
                          <input
                            className="font-sm color-text-paragraph-2"
                            name="phone"
                            placeholder="Phone number"
                            type="tel"
                            value={data.phone}
                            onChange={onChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12 col-md-12">
                        <div className="textarea-style mb-30">
                          <textarea
                            className="font-sm color-text-paragraph-2"
                            name="message"
                            placeholder="Tell us about yourself"
                            value={data.message}
                            onChange={onChange}
                          />
                        </div>
                        <button
                          className="submit btn btn-send-message"
                          type="submit"
                        >
                          Send message
                        </button>
                        <label className="ml-20">
                          <input
                            className="float-start mr-5 mt-6"
                            type="checkbox"
                            name="agree"
                            onChange={onChangeAgree}
                          />{' '}
                          By clicking contact us button, you agree our terms and
                          policy,
                        </label>
                      </div>
                    </div>
                  </form>
                  <p className="form-messege"></p>
                  <ToastContainer
                    position="bottom-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                  />
                </div>
                <div className="col-lg-4 text-center d-none d-lg-block">
                  <img src="assets/imgs/page/contact/img.png" alt="joxBox" />
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  )
}
