import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { withFirebase } from '../../firebase'
import { getJob } from '../../db/job'
import Details from './details'

const JobPrint = (props) => {
  let { id } = useParams()
  const [loading, setLoading] = useState(true)
  const [job, setJob] = useState(null)

  const db = props.firebase.getdb()

  useEffect(() => {
    getJob(db, id).then((item) => {
      setJob({ ...item })
      setLoading(false)
    })
  }, [])

  if (!job) {
    return null
  }

  return (
    <section className="section-box mt-50 text-start">
      <div className="container">
        <div className="main-header mb-30">
          <div className="header-left">
            <div className="header-logo">
              <img
                alt="findAJobUSA"
                src="/assets/imgs/template/logo-small.png"
              />
            </div>
          </div>
        </div>
        {loading && <span>Loading data...</span>}
        {!loading && <Details data={job} />}
      </div>
    </section>
  )
}

export default withFirebase(JobPrint)
