import SwiperCore, { Navigation } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Link } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { getIndustryCounters } from '../../../db/job'
import industries from '../../../constants/Industries'

SwiperCore.use([Navigation])

const splitArray = (arr) => {
  arr = arr.slice(0, 10)
  const midpoint = Math.floor(arr.length / 2)
  const arrayOne = arr.slice(0, midpoint)
  const arrayTwo = arr.slice(midpoint)
  return { arrayOne, arrayTwo }
}

const CategorySlider = ({ db }) => {
  const [industryCounters, setIndustryCounters] = useState()

  useEffect(() => {
    if (db && !industryCounters) {
      // getJobIndustryCounters(db).then((result) => {
      //   updateIndustryCounters(db, result).then(() => {
      //     console.log('counter update done')
      //   })
      // })
      getIndustryCounters(db).then((result) =>
        setIndustryCounters(result ?? {}),
      )
    }
  }, [db, industryCounters])

  const { arrayOne, arrayTwo } = splitArray(industries)

  if (!industryCounters) {
    return <></>
  }

  return (
    <>
      <div className="swiper-container swiper-group-5">
        <Swiper
          slidesPerView={5}
          spaceBetween={30}
          navigation={{
            prevEl: '.swiper-button-prev',
            nextEl: '.swiper-button-next',
          }}
          breakpoints={{
            320: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            575: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            767: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            991: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1199: {
              slidesPerView: 5,
              spaceBetween: 30,
            },
          }}
          className="swiper-wrapper pb-70 pt-5 swiper-grid-jobobx"
        >
          {arrayOne &&
            arrayOne.map((item, i) => (
              <SwiperSlide key={i}>
                <div
                  className="swiper-slide hover-up h-100"
                  style={{ minHeight: '8rem' }}
                >
                  <Link to={`/jobs?industries=${item.key}`}>
                    <div className="item-logo flex-nowrap h-100">
                      <div className="image-left">
                        <img
                          alt="findAJobUSA"
                          src={`assets/imgs/page/homepage1/jobs_assets/${item.icon}`}
                        />
                      </div>
                      <div className="text-info-right overflow-hidden">
                        <h4 className="ellipsis-text mt-10">{item.value}</h4>
                        {/*{industryCounters && (*/}
                        {/*  <p className="font-xs">*/}
                        {/*    {industryCounters[item.key] ?? 0}*/}
                        {/*    <span> Jobs Available</span>*/}
                        {/*  </p>*/}
                        {/*)}*/}
                      </div>
                    </div>
                  </Link>
                </div>

                {arrayTwo[i] && (
                  <div className="swiper-slide hover-up h-100">
                    <Link to={`/jobs?industries=${arrayTwo[i].key}`}>
                      <div className="item-logo flex-nowrap h-100">
                        <div className="image-left">
                          <img
                            alt="findAJobUSA"
                            src={`assets/imgs/page/homepage1/jobs_assets/${arrayTwo[i].icon}`}
                          />
                        </div>
                        <div className="text-info-right overflow-hidden">
                          <h4 className="ellipsis-text mt-10">
                            {arrayTwo[i].value}
                          </h4>
                          {/*{industryCounters && (*/}
                          {/*  <p className="font-xs">*/}
                          {/*    {industryCounters[arrayTwo[i].key] ?? 0}*/}
                          {/*    <span> Jobs Available</span>*/}
                          {/*  </p>*/}
                          {/*)}*/}
                        </div>
                      </div>
                    </Link>
                  </div>
                )}
              </SwiperSlide>
            ))}
        </Swiper>
      </div>
      <div className="swiper-button-next" />
      <div className="swiper-button-prev" />
    </>
  )
}

export default CategorySlider
